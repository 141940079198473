import { FeatureType, MeQuery } from "@gql/generated/graphql";
import { Workspace } from "./Workspace";

type RawProfile = MeQuery["meV2"]["profile"];
type RawFlags = RawProfile["flags"];

// TODO: CompanyProfile should be the main entity here
export class Profile {
  id: string;

  displayName: string;

  photoUrl: string | null;

  acceptedLegal: boolean;

  createdAt: string;

  flags: RawFlags;

  enabledFeatures: FeatureType[];

  transformedMediaUrl: string | null;

  isSuperUser: boolean;

  workspaces: Workspace[];

  constructor(profile?: RawProfile) {
    this.id = profile?.id ?? "";
    this.displayName = profile?.preferredName || profile?.displayName || "";
    this.acceptedLegal = profile?.acceptedLegal ?? false;
    this.createdAt = profile?.createdAt ?? "";
    this.photoUrl = profile?.media?.mediaUrl ?? null;
    this.flags = profile?.flags ?? [];
    this.enabledFeatures = profile?.enabledFeatures ?? [];
    this.transformedMediaUrl = profile?.media?.mediaUrl ?? null;
    this.isSuperUser = profile?.isSuperuser ?? false;
    this.workspaces =
      profile?.workspaces.nodes.map((workspace) => new Workspace(workspace)) ??
      [];
  }
}
