import { Language, MeQuery } from "@gql/generated/graphql";
import { Company } from "./Company";
import { Profile } from "./Profile";

type RawCompanyProfile = MeQuery["meV2"];

export class CompanyProfile {
  id: string;

  externalId: string;

  companyId: string;

  isCoach: boolean;

  hasCoachedReps: boolean;

  hasSocialReps: boolean;

  phoneNumber: string;

  emailAddress: string;

  score: number;

  excludeFromAnalytics: boolean;

  language: Language | null | undefined;

  company: Company;

  profile: Profile;

  constructor(companyProfile?: RawCompanyProfile) {
    this.id = companyProfile?.id ?? "";
    this.externalId = companyProfile?.externalId ?? "";
    this.companyId = companyProfile?.companyId ?? "";
    this.phoneNumber = companyProfile?.phoneNumber ?? "";
    this.emailAddress = companyProfile?.emailAddress ?? "";
    this.score = companyProfile?.score ?? 0;
    this.isCoach = companyProfile?.isCoach ?? false;
    this.hasCoachedReps = companyProfile?.hasCoachedReps ?? false;
    this.hasSocialReps = companyProfile?.hasSocialReps ?? false;
    this.excludeFromAnalytics = companyProfile?.excludeFromAnalytics ?? false;
    this.language = companyProfile?.language;
    this.company = new Company(companyProfile?.company);
    this.profile = new Profile(companyProfile?.profile);
  }
}
