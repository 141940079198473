import as from "./as.json";
import bn_bd from "./bn_BD.json";
import bn from "./bn.json";
import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fil from "./fil.json";
import fr from "./fr.json";
import gu from "./gu_IN.json";
import hi from "./hi.json";
import id from "./id.json";
import ja from "./ja.json";
import kn from "./kn.json";
import ko from "./ko.json";
import ml from "./ml.json";
import or from "./or_IN.json";
import pt_br from "./pt_BR.json";
import si from "./si.json";
import ta from "./ta.json";
import te from "./te.json";
import tr from "./tr.json";
import vi_vn from "./vi_VN.json";
import zh_cn from "./zh_Hans_CN.json";

//exported values should match react intl's accepted locale language
// eslint-disable-next-line import/no-default-export, import/no-anonymous-default-export
export default {
  as,
  "bn-bd": bn_bd,
  bn,
  de,
  en,
  es,
  fil,
  fr,
  gu,
  hi,
  id,
  ja,
  kn,
  ko,
  ml,
  or,
  "pt-br": pt_br,
  si,
  ta,
  te,
  tr,
  "vi-vn": vi_vn,
  "zh-cn": zh_cn,
};
