import { Language, MeQuery } from "@gql/generated/graphql";

type RawCompany = MeQuery["meV2"]["company"];

export class Company {
  id: string;

  name: string;

  internalName: string;

  displayName: string;

  externalId: string;

  languages: Language[];

  defaultLanguage: Language;

  logo: string;

  constructor(company?: RawCompany) {
    this.id = company?.id ?? "";
    this.name = company?.displayName ?? company?.name ?? "";
    this.internalName = company?.name ?? "";
    this.displayName = company?.displayName ?? "";
    this.externalId = company?.externalId ?? "";
    this.languages = this.adaptLanguages(company?.companyLanguages.nodes ?? []);
    this.defaultLanguage = company?.defaultLanguage ?? Language.En;
    this.logo = company?.logoMedia?.path ?? "";
  }

  adaptLanguages(
    companyLanguages: RawCompany["companyLanguages"]["nodes"]
  ): Language[] {
    return companyLanguages
      .map((companyLanguage) => companyLanguage.language)
      .sort();
  }
}
