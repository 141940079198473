import { PwaIdentificationType } from "@bigspring/core-components/src/lib/segment";
import { Company } from "@shared/AuthenticationProvider/models/Company";
import { CompanyProfile } from "@shared/AuthenticationProvider/models/CompanyProfile";
import { amIInAuthOverrideMode, getItem, AUTH_KEYS } from "@utils/auth";

export const getIdentifyData = (
  companyProfile: CompanyProfile,
  company: Company
): PwaIdentificationType | null => {
  const flagMap = (
    companyProfile.profile.flags as {
      name: string;
      value: string;
    }[]
  ).reduce<Record<string, string>>(
    (acc, flag) => ({ ...acc, [flag.name]: flag.value }),
    {}
  );
  const homeHeroSection = flagMap["home_hero_section"];
  const homeHeroSectionEnabled =
    !!homeHeroSection &&
    (homeHeroSection === "true" || homeHeroSection === "treatment");

  const authOverrideOriginalExternalId = amIInAuthOverrideMode()
    ? (getItem(AUTH_KEYS.AUTHOVERRIDE_COMPANY_EXTERNAL_ID) as string)
    : null;

  try {
    return {
      email: companyProfile.emailAddress,
      name: companyProfile.profile.displayName,
      firstName: companyProfile.profile.displayName
        ? companyProfile.profile.displayName.split(" ")[0] || null
        : null,
      lastName: companyProfile.profile.displayName
        ? companyProfile.profile.displayName.split(" ")[1] || null
        : null,
      phone: companyProfile.phoneNumber,
      createdAt: companyProfile.profile.createdAt,
      isCoach: companyProfile.isCoach,
      userId: companyProfile.profile.id,
      externalId: companyProfile.externalId,
      selectedLanguage: companyProfile.language,
      totalPoints: companyProfile.score,
      company: company.internalName,
      companyDisplayName: company.displayName,
      companyId: company.id,
      companyExternalId: company.externalId,
      companyProfileId: companyProfile.id,
      isSuperAdmin: companyProfile.profile.isSuperUser,
      excludeFromAnalytics: companyProfile.excludeFromAnalytics,
      pathname: undefined,
      flags: flagMap,
      authOverrideOriginalExternalId,
      homeHeroSectionEnabled,
    };
  } catch (error) {
    console.error("Error PWA identification", error);
  }

  return null;
};
