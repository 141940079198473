import { MeQuery } from "@gql/generated/graphql";

type RawWorkspace = MeQuery["meV2"]["profile"]["workspaces"]["nodes"][0];

export class Workspace {
  id: string;

  externalId: string;

  companyId: string;

  companyName: string;

  companyInternalName: string;

  companyExternalId: string;

  companyLogo: string | null;

  constructor(workspace?: RawWorkspace) {
    this.id = workspace?.id ?? "";
    this.externalId = workspace?.externalId ?? "";
    this.companyId = workspace?.companyId ?? "";
    this.companyName = workspace?.companyName ?? "";
    this.companyInternalName = workspace?.companyInternalName ?? "";
    this.companyExternalId = workspace?.companyExternalId ?? "";
    this.companyLogo = workspace?.companyLogo ?? null;
  }
}
