"use client";

import { IntlProvider as IntlProviderBase } from "react-intl";
import { PropsWithChildren, useMemo } from "react";
import translations from "@lib/localization";
import { OnErrorFn } from "@formatjs/intl/src/types";
import { IntlErrorCode } from "@formatjs/intl/src/error";
import { useAuth } from "../AuthenticationProvider";
import { Language } from "@gql/generated/graphql";

export type Translations = keyof typeof translations;

const onError: OnErrorFn = (error) => {
  if (
    error.code === IntlErrorCode.MISSING_TRANSLATION ||
    error.code === IntlErrorCode.MISSING_DATA
  ) {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== "production") {
      console.warn("[intl][", error.code, "] ", error.message);
    }

    return;
  }

  throw error;
};

/**
 * Returns the translations for the given language.
 * The string in defaultLanguage prop of FormattedMessage component
 * will be shown when the language prop is undefiend or null.
 * The translation key will be shown in there is no defaultLanguage defined
 * in FormattedMessage component.
 */
export function IntlProvider({ children }: PropsWithChildren<unknown>) {
  const { user } = useAuth();

  const language = useMemo(() => {
    let result = user.company.defaultLanguage;

    // If no user then return browser's language if exists, enlgish otherwise
    if (!user.id && typeof window !== "undefined") {
      const browserLanguage = window.navigator.language.split(
        "-"
      )[0] as Translations;
      result = translations[browserLanguage]
        ? (window.navigator.language.split("-")[0] as Language)
        : Language.En;
    }

    if (user.language && user.company.languages.includes(user.language)) {
      result = user.language;
    }

    // react-intl provider locale doesn not accept underscores
    return result.replace("_", "-").toLowerCase() as Translations;
  }, [
    user.company.defaultLanguage,
    user.company.languages,
    user.id,
    user.language,
  ]);

  let messages = {};
  if (language) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    messages = translations[language] as Record<Translations, string>;
  }

  return (
    <IntlProviderBase
      locale={language || "en"}
      messages={messages}
      onError={onError}
      textComponent="span"
    >
      {children as any}
    </IntlProviderBase>
  );
}
